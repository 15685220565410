import Vue from "vue";
import Vuex from "vuex";
import * as auth from "./modules/auth";
import * as organizations from "./modules/organizations";
import * as courses from "./modules/courses";
import * as assignments from "./modules/assignments";
import * as lti from "./modules/lti";
import * as submissions from "./modules/submissions";
import * as studentExtension from "./modules/studentExtension";
import * as users from "./modules/users";
import * as eventLogs from "./modules/eventLogs";
import * as sections from "./modules/sections";
import * as googleClassroom from "./modules/googleClassroom";
import * as googleDriveFiles from "./modules/googleDriveFiles";
import * as dragAndDrop from "./modules/dragAndDrop";
import * as bucketFiles from "@learnics/services/src/vue/store/bucketFiles";
import * as noteBoards from "@learnics/services/src/vue/store/noteBoards";
import * as notes from "@learnics/services/src/vue/store/notes";
import * as researchNotebooks from "@learnics/services/src/vue/store/researchNotebooks";
import * as textEditors from "@learnics/services/src/vue/store/textEditors";
import * as acl from "@learnics/services/src/vue/store/acl";
import * as tags from "@learnics/services/src/vue/store/tags";
import * as oldSessions from "@learnics/services/src/vue/store/oldSessions";
import * as asyncCommandStatus from "@learnics/services/src/vue/store/asyncCommandStatus";

Vue.use(Vuex);

export const store = new Vuex.Store({
  actions: {
    // Define some universal actions here
  },

  modules: {
    auth,
    lti,
    organizations,
    courses,
    assignments,
    submissions,
    studentExtension,
    users,
    eventLogs,
    sections,
    googleClassroom,
    googleDriveFiles,
    dragAndDrop,
    acl,
    tags,
    bucketFiles,
    textEditors,
    notes,
    noteBoards,
    researchNotebooks,
    oldSessions,
    asyncCommandStatus,
  },
});
