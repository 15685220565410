import { activeTipTapExtensions } from "@learnics/models/src/utils/tiptap/activeTipTapExtensions";
import { InTextSelection } from "@learnics/models/src/utils/tiptap/InTextSelectionExtension";
import { BucketFile } from "@learnics/services/src/vue/components/RichTextEditor/customExtensions/BucketFileExtension";
import { Image } from "@tiptap/extension-image";
import { PastedImage } from "@learnics/services/src/vue/components/RichTextEditor/customExtensions/PastedImageExtension";
// import { CustomDropCursor } from "@learnics/services/src/vue/components/RichTextEditor/customExtensions/CustomDropCursorExtension";

export function initializeCustomTipTapExtensions() {
  activeTipTapExtensions.add(BucketFile);
  activeTipTapExtensions.add(InTextSelection);
  activeTipTapExtensions.add(Image);
  activeTipTapExtensions.add(PastedImage);
  // activeTipTapExtensions.add(CustomDropCursor);
}
