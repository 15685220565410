import { CommonDomainDataRc } from "@learnics/models/src/Organization/Course/Assignment/Event/RunningCalculator/CommonDomainDataRc";
import { CommonSearchesRc } from "@learnics/models/src/Organization/Course/Assignment/Event/RunningCalculator/CommonSearchesRc";
import { CommonSearchWordFrequenciesRc } from "@learnics/models/src/Organization/Course/Assignment/Event/RunningCalculator/CommonSearchWordFrequenciesRc";
import { CommonUrlCategoriesRc } from "@learnics/models/src/Organization/Course/Assignment/Event/RunningCalculator/CommonUrlCategoriesRc";
import { CommonUrlDataRc } from "@learnics/models/src/Organization/Course/Assignment/Event/RunningCalculator/CommonUrlDataRc";
import { loggerSessionFromJson } from "@learnics/models/src/Organization/Course/Assignment/Submission/loggerSessionFromJson";
import { OldLoggerSession } from "@learnics/models/src/Organization/Course/Assignment/Submission/OldLoggerSession";
import { LoggerSession } from "@learnics/models/src/LoggerSession/LoggerSession";

import Vue from "vue";
import LoggerService from "@learnics/services/src/logger";
import LoggerUserDataService from "@learnics/services/src/loggerUserData";

export const state = {
  events: {},
  eventMetaInfos: {},
  secrets: {},
  keysDeleted: {},
  keysBlacklisted: {},
  loggerSessions: {},
  runningCalculators: {},
  commonRunningCalculators: {},
  //TODO: Remove this when collaborative Y.js research notebook / notes are fully implemented
  userSiteData: {},
  loadingAnyEventData: 0,
  loadingEventLogData: {},
};

export const getters = {
  getEvents: (state) => state.events,
  getEventMetaInfos: (state) => state.eventMetaInfos,
  getSecrets: (state) => state.secrets,
  getKeysDeleted: (state) => state.keysDeleted,
  getKeysBlacklisted: (state) => state.keysBlacklisted,
  getLoggerSessions: (state) => state.loggerSessions,
  getRunningCalculators: (state) => state.runningCalculators,
  getCommonRunningCalculators: (state) => state.commonRunningCalculators,
  //TODO: Remove this when collaborative Y.js research notebook / notes are fully implemented
  getUserSiteData: (state) => state.userSiteData,

  getLoadingAnyEventData: (state) => state.loadingAnyEventData,
  getLoadingEventLogData: (state) => state.loadingEventLogData,
};

export const mutations = {
  updateLoadingAnyEventData(state, payload) {
    if (!payload) throw new Error("Payload is null");
    const { loading = false } = payload;
    state.loadingAnyEventData = loading
      ? state.loadingAnyEventData + 1
      : state.loadingAnyEventData - 1;
  },
  addRunningCalculator(state, payload) {
    if (!payload) throw new Error("Payload is null");
    if (!payload.id) throw new Error("Payload.id is null");
    if (!payload.parentId) throw new Error("Payload.parentId is null");
    const target = payload.common
      ? "commonRunningCalculators"
      : "runningCalculators";

    if (!payload.calculator && state[target][payload.parentId]) {
      Vue.delete(state[target][payload.parentId], payload.id);
      if (Object.keys(state[target][payload.parentId]).length === 0) {
        Vue.delete(state[target], payload.parentId);
      }
    } else if (payload.calculator) {
      if (!state[target][payload.parentId]) {
        Vue.set(state[target], payload.parentId, {});
      }
      Vue.set(state[target][payload.parentId], payload.id, payload.calculator);
    }
  },
  addRunningCalculators(state, payload) {
    if (!payload) throw new Error("Payload is null");
    if (!payload.calculators) throw new Error("Payload.calculators is null");
    if (!payload.sessionId)
      throw new Error(
        "Payload.sessionId is null.  A session ID is required to add running calculators."
      );
    const target = payload.common
      ? "commonRunningCalculators"
      : "runningCalculators";
    const parentId = payload.common ? payload.assignmentId : payload.sessionId;
    Vue.set(state[target], parentId, payload.calculators);
  },
  addLoggerSession(state, payload) {
    if (!payload) throw new Error("Payload is null");
    if (
      state.loggerSessions[payload.id] &&
      state.loggerSessions[payload.id] instanceof LoggerSession &&
      !(payload instanceof OldLoggerSession)
    ) {
      // compatibility code
      const json = payload.toJson();
      json.researchNotebookId =
        state.loggerSessions[payload.id].researchNotebookId;
      json.version = state.loggerSessions[payload.id].version;
      payload = loggerSessionFromJson(json);
    }
    Vue.set(state.loggerSessions, payload.id, payload);
  },
  addEvents(state, payload) {
    if (!payload) throw new Error("Payload is null");
    if (!payload.events) throw new Error("Payload.events is null");
    if (!payload.sessionId) throw new Error("Payload.sessionId is null");
    Vue.set(state.events, payload.sessionId, Object.freeze(payload.events));
  },
  addSecrets(state, payload) {
    if (!payload) throw new Error("Payload is null");
    if (!payload.sessionId) throw new Error("Payload.sessionId is null");
    if (!payload.secrets) {
      Vue.delete(state.secrets, payload.sessionId);
    } else {
      Vue.set(state.secrets, payload.sessionId, Object.freeze(payload.secrets));
    }
  },
  addKeysDeleted(state, payload) {
    if (!payload) throw new Error("Payload is null");
    if (!payload.keysDeleted) throw new Error("Payload.keysDeleted is null");
    if (!payload.sessionId) throw new Error("Payload.sessionId is null");
    Vue.set(
      state.keysDeleted,
      payload.sessionId,
      Object.freeze(payload.keysDeleted)
    );
  },

  addKeysBlacklisted(state, payload) {
    if (!payload) throw new Error("Payload is null");
    if (!payload.keysBlacklisted)
      throw new Error("Payload.keysBlacklisted is null");
    if (!payload.sessionId) throw new Error("Payload.sessionId is null");
    Vue.set(
      state.keysBlacklisted,
      payload.sessionId,
      Object.freeze(payload.keysBlacklisted)
    );
  },

  addEventMetaInfo(state, payload) {
    if (!payload) throw new Error("Payload is null");
    if (!payload.eventMetaInfo)
      throw new Error("Payload.eventMetaInfo is null");
    if (!payload.sessionId) throw new Error("Payload.sessionId is null");

    Vue.set(
      state.eventMetaInfos,
      payload.sessionId,
      Object.freeze(payload.eventMetaInfo)
    );
  },
  //TODO: Remove this when collaborative Y.js research notebook / notes are fully implemented
  /** @deprecated */
  addUserSiteData(state, payload) {
    if (!payload) throw new Error("Payload is null");
    if (!payload.sessionId) throw new Error("Payload.sessionId is null");
    if (!payload.hashedUrl) throw new Error("Payload.hashedUrl is null");
    if (payload.value) {
      if (!state.userSiteData[payload.sessionId]) {
        Vue.set(state.userSiteData, payload.sessionId, {});
      }

      Vue.set(
        state.userSiteData[payload.sessionId],
        payload.hashedUrl,
        payload.value
      );
    } else if (state.userSiteData[payload.sessionId]) {
      Vue.delete(state.userSiteData[payload.sessionId], payload.hashedUrl);
    }
  },
  updateEventLogDataLoading(state, payload) {
    if (!payload) throw new Error("Payload is null");
    if (!payload.sessionId) throw new Error("Payload.sessionId is null");
    const currentVal = state.loadingEventLogData[payload.sessionId] || 0;
    Vue.set(
      state.loadingEventLogData,
      payload.sessionId,
      currentVal + (payload.value ? 1 : -1)
    );
  },
};

export const actions = {
  async loadPlainEventMetaInfo(
    { state, commit, dispatch, rootState },
    args = { forced: false }
  ) {
    const sessionId = args.sessionId;
    if (!sessionId) throw new Error("No sessionId provided");

    if (!args.forced && state.eventMetaInfos[sessionId]) return;
    try {
      let eventMetaInfo = await LoggerService.getEventMetaInfo(sessionId);
      let session = await LoggerService.getLoggerSession(sessionId);
      if (session instanceof OldLoggerSession) {
        commit("setOldSession", {
          compositeId: { id: sessionId },
          value: loggerSessionFromJson(session.toJson()),
        });
      }
      commit("addLoggerSession", session);
      commit("addEventMetaInfo", {
        sessionId,
        eventMetaInfo,
      });
    } catch (error) {
      console.log(
        "There was an error loading the eventMetaInfo/loggerSession: " + error
      );
      throw error;
    }
  },

  async loadPlainEventLogSubmissionData(
    { state, commit, dispatch, rootState },
    args = { forced: false }
  ) {
    const { sessionId, timeRange } = args;

    await dispatch("loadPlainEventMetaInfo", {
      sessionId,
    });

    const metaInfo = state.eventMetaInfos[sessionId];
    if (!metaInfo) throw new Error("No metaInfo found for sessionId.");
    if (!args.forced && state.events[sessionId]) return;

    try {
      // Determine the user id

      const { events, secrets, keysDeleted, keysBlacklisted, calculators } =
        await LoggerService.loadRunningCalculators(
          sessionId,
          [],
          [
            "submissionsRc",
            "urlCategoriesRc",
            "urlTimelinesRc",
            "domainDataRc",
            "searchesRc",
            "searchWordFrequenciesRc",
          ],
          null,
          {},
          { timeRange }
        );
      if (events) {
        commit("addEvents", {
          sessionId,
          events,
        });
      }
      commit("addRunningCalculators", {
        sessionId,
        calculators: { ...calculators },
        common: false,
      });
      commit("addSecrets", {
        sessionId,
        secrets,
      });
      commit("addKeysDeleted", {
        sessionId,
        keysDeleted,
      });
      commit("addKeysBlacklisted", {
        sessionId,
        keysBlacklisted,
      });
    } catch (error) {
      console.log("There was an error loading the eventMetaInfo: " + error);
      throw error;
    }
  },

  async loadEventLogSubmissionData(
    { state, commit, dispatch, rootState },
    args = { forced: false }
  ) {
    if (!args) throw new Error("No args provided");
    const { sessionId, assignmentId, timeRange } = args;
    if (!sessionId) throw new Error("No sessionId provided");
    if (!assignmentId) throw new Error("No assignmentId provided");

    commit("updateEventLogDataLoading", {
      sessionId: sessionId,
      value: true,
    });
    await dispatch("loadPlainEventMetaInfo", {
      sessionId,
      assignmentId,
    });

    const metaInfo = state.eventMetaInfos[sessionId];
    if (!metaInfo) throw new Error("No metaInfo found for sessionId.");
    const assignment = rootState.assignments.assignments[assignmentId];
    if (!assignment) throw new Error("Assignment not found. Load it first.");

    let commonCalculators = state.commonRunningCalculators[assignmentId];
    if (!commonCalculators) {
      console.log(
        "No common calculators found for assignmentId: " +
          assignmentId +
          ".  Creating them."
      );
      commonCalculators = {
        commonDomainDataRc: new CommonDomainDataRc({}),
        commonSearchesRc: new CommonSearchesRc({}),
        commonSearchWordFrequenciesRc: new CommonSearchWordFrequenciesRc({}),
        commonUrlDataRc: new CommonUrlDataRc({}),
        commonUrlCategoriesRc: new CommonUrlCategoriesRc({}),
      };

      commit("addRunningCalculators", {
        sessionId,
        assignmentId,
        calculators: { ...commonCalculators },
        common: true,
      });
    }
    const assignmentDetail =
      rootState.assignments.assignmentDetails[assignment.assignmentDetailId];
    if (!assignmentDetail)
      throw new Error("Assignment detail not found.  Load it first.");

    if (
      !args.forced &&
      state.loadingEventLogData[sessionId] < 2 &&
      state.events[sessionId]
    ) {
      commit("updateEventLogDataLoading", {
        sessionId: sessionId,
        value: false,
      });
      return;
    }

    try {
      // Determine the user id
      commit("updateLoadingAnyEventData", { loading: true });

      const uids = Object.keys(
        rootState.assignments.assignmentKeys[assignmentId] || {}
      );
      const foundUid = uids.find(
        (uid) =>
          rootState.assignments.assignmentKeys[assignmentId][uid] === sessionId
      );
      if (state.runningCalculators[sessionId]) {
        // We're setting a new set of running calculators for a session.  Remove the old ones
        // from the common calculations.
        const commonCalcIds = Object.keys(
          state.commonRunningCalculators[assignmentId] || {}
        );
        for (let i = 0; i < commonCalcIds.length; i++) {
          state.commonRunningCalculators[assignmentId][
            commonCalcIds[i]
          ].addSession(
            state.runningCalculators[sessionId],
            state.secrets[sessionId],
            -1
          );
        }
      }
      const { events, secrets, keysDeleted, keysBlacklisted, calculators } =
        await LoggerService.getBackwardCompatibleRunningCalculators(
          sessionId,
          assignment,
          assignmentDetail,
          foundUid,
          metaInfo,
          [
            "submissionsRc",
            "urlCategoriesRc",
            "urlTimelinesRc",
            "domainDataRc",
            "searchesRc",
            "searchWordFrequenciesRc",
          ],
          commonCalculators,
          {
            timeRange,
          }
        );
      if (events) {
        commit("addEvents", {
          sessionId: sessionId,
          events,
        });
      }
      commit("addRunningCalculators", {
        sessionId,
        assignmentId,
        calculators: { ...calculators },
        common: false,
      });
      commit("addRunningCalculators", {
        sessionId,
        assignmentId,
        calculators: { ...commonCalculators },
        common: true,
      });
      commit("addSecrets", {
        sessionId: sessionId,
        secrets,
      });
      commit("addKeysDeleted", {
        sessionId: sessionId,
        keysDeleted,
      });
      commit("addKeysBlacklisted", {
        sessionId: sessionId,
        keysBlacklisted,
      });
    } catch (error) {
      console.error("There was an error loading the eventMetaInfo: " + error);
      commit("updateEventLogDataLoading", {
        sessionId: sessionId,
        value: false,
      });
      commit("updateLoadingAnyEventData", { loading: false });

      throw error;
    }

    commit("updateEventLogDataLoading", {
      sessionId: sessionId,
      value: false,
    });
    commit("updateLoadingAnyEventData", { loading: false });
  },

  //TODO: Remove this when collaborative Y.js research notebook / notes are fully implemented
  /** @deprecated */
  async loadUserSiteData(
    { state, commit, dispatch, rootState },
    args = { forced: false }
  ) {
    const { sessionId } = args;
    const userSiteData = await LoggerUserDataService.getUserSiteDatas(
      sessionId
    );
    userSiteData.forEach((v) => {
      commit("addUserSiteData", {
        sessionId: v.sessionId,
        hashedUrl: v.id,
        value: v,
      });
    });
  },
};
