function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}

export default {
  path: ":noteBoardId",
  name: "StudentAssignmentNoteBoardRoot",
  component: lazyLoad("StudentAssignmentNoteBoardRoot"),
  props: true,
  redirect: "./",

  children: [
    {
      path: "",
      name: "StudentAssignmentNoteBoardPortal",
      component: lazyLoad("StudentAssignmentNoteBoardPortal"),
      props: true,
    },
    {
      path: "home",
      name: "StudentAssignmentNoteBoardView",
      component: lazyLoad("StudentAssignmentNoteBoardView"),
      props: true,
    },
  ],
};
