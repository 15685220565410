import { auth } from "@learnics/services/src/analyticsConfig";
import GapiOauth2Service from "@learnics/services/src/gapiOauth2";
import { customLoginWithPopup } from "@/utils/gapi/customLoginWithPopup";

export async function authorizeGapiScopes(scopes) {
  console.log("Authorizing gapi scopes:", scopes);
  const uid = auth.currentUser?.uid;
  let gapiTokens = JSON.parse(localStorage.getItem("gapiTokens") || "{}");
  let gapiToken = uid && gapiTokens[uid];
  const { missingScopes, newGapiToken } =
    await GapiOauth2Service.validateGapiTokenForScopes(scopes, uid, gapiToken);

  if (newGapiToken) {
    gapiToken = newGapiToken;
    gapiTokens[uid] = gapiToken;
    localStorage.setItem("gapiTokens", JSON.stringify(gapiTokens));
  }

  if (!gapiToken) {
    missingScopes.push(...scopes);
  }

  if (missingScopes.length === 0) {
    return gapiToken;
  }

  const loginResult = await customLoginWithPopup(
    {},
    auth.currentUser,
    missingScopes
  );
  const tokens = loginResult.tokens;
  if (tokens) {
    gapiTokens = JSON.parse(localStorage.getItem("gapiTokens") || "{}");
    gapiTokens[uid] = tokens;
    localStorage.setItem("gapiTokens", JSON.stringify(gapiTokens));
    return tokens;
  } else {
    console.log("Failed to authorize scopes:", loginResult);
    throw new Error("Failed to authorize scopes");
  }
}
