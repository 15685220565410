import Vue from "vue";

export const state = {
  draggingItem: null,
};

export const getters = {
  draggingItem: (state) => state.draggingItem,
};

export const mutations = {
  setDraggingItem(state, payload) {
    Vue.set(state, "draggingItem", payload);
  },
};

export const actions = {};
